<template>
  <div class="statusSuccess w-100 h-100">
    <ChatComponentsLeftAside />
    <div class="mainBlock h-100 customerConv">
      <div class="chatContent h-100">
        <div ref="convContent" class="convContent">
          <div v-for="item in massages" :key="item.name">
            <!-- 分配成功提示語 -->
            <div v-if="item.msgType === 'welcome' " class="welcomeMsg mb-10">
              {{ item.fullContent }}
            </div>

            <div v-if="item.msgType !== 'welcome' && item.msgType !== 'finish'" class="msgBlock">
              <!-- 頭像 -->
              <template v-if="item.show && item.group == 'left'">
                <div class="msgTitle">
                  <v-avatar
                    :image="userInfo.domain + item.agentAvatar"
                    size="25"
                    class="me-2 ms-2"
                  />
                  <span v-if="item.userName">{{ item.userName }}</span>
                </div>
              </template>

              <div class="message" :class="{'rightStyle': item.group == 'right' }">
                <!-- 系統提示語 -->
                <div
                  v-if="item.senderType == 'system' && item.msgType !== 'finish'"
                  class="msgContent systemMsg"
                >
                  {{ item.fullContent }}
                </div>

                <!-- 對話 -->
                <div
                  v-if="item.msgType == 'message'"
                  class="msgContent"
                  :class="item.group == 'left'? 'leftMsg':'rightMsg'"
                >
                  {{ item.fullContent }}
                </div>

                <!-- 時間 -->
                <span class="msgTime">{{ monthFormat(item.createdAt) }}</span>
              </div>
            </div>

            <!-- 結束提示語 -->
            <div v-if="item.msgType === 'finish' " class="finishMsg mt-10 mb-10">
              {{ item.fullContent }}
            </div>
          </div>
        </div>
        <div class="chatInput">
          <!-- 工具 -->
          <div v-if="!isFinishedCon" class="toolBar">
            <CommonButton>
              <v-icon icon="fa-regular fa-face-smile" />
            </CommonButton>
            <CommonButton>
              <v-icon icon="fa-regular fa-image" />
            </CommonButton>
            <CommonButton>
              <v-icon icon="fa-solid fa-film" />
            </CommonButton>
            <CommonButton>
              <v-icon icon="fa-solid fa-music" />
            </CommonButton>
            <CommonButton>
              <v-icon icon="fa-regular fa-folder-closed" />
            </CommonButton>
            <v-divider v-if="!visitorEndChatEnabled" vertical class="ms-2 me-2" />
            <CommonButton v-if="!visitorEndChatEnabled" @click="finishCon()">
              <v-icon icon="fa-solid fa-right-from-bracket me-2" /> 離開對話
            </CommonButton>
          </div>
          <!-- 對話輸入框 -->
          <div v-if="!isFinishedCon" class="inputContent">
            <v-text-field v-model="contentValue" variant="underlined" hide-details placeholder="請輸入內容" @keyup.enter="sendMsg()" />
            <CommonButton class="ms-2 sendBtn" @click="sendMsg()">
              <v-icon icon="fa-solid fa-paper-plane" class="me-2" />
              發送
            </CommonButton>
          </div>
          <div v-if="isFinishedCon" class="finishedCon">
            當前對話已結束，您可以選擇【<span class="continueCon" @click="newCon(true)">繼續對話</span>】
          </div>
        </div>
      </div>
    </div>
    <ChatComponentsRightAside />
  </div>
</template>

<script setup>
const userInfo = useUserInfo()
const convInfo = useConvInfo()

const { sendMsgFn, finishConFn, newConFn } = inject('chatProvide')

const props = defineProps({
  isFinishedCon: {
    type: Boolean,
    default: false
  },
  visitorEndChatEnabled: {
    type: Boolean,
    default: false
  }
})

// 對話內容 ----
const massages = reactive(convInfo.currentChatMsg)

// 發送對話
const contentValue = ref('')
const sendMsg = () => {
  sendMsgFn(contentValue.value)
  contentValue.value = ''
}

// 結束對話
const finishCon = () => {
  finishConFn()
}

// 繼續對話
const newCon = (continueCon) => {
  newConFn(continueCon)
}
</script>

<style lang="scss" scoped>
@import "assets/styles/pages/chat.scss";
</style>
