import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

// request(REQ) Api  --------------------------------
const base = '/api/message'
const modules = '/customers'
const path = base + modules

const visitorFinishConREQ = path + '/conversation/' // 訪客結束會話
const visitorHistoryREQ = path + '/conversation/messages?userId=' // (訪客)獲取對話歷史訊息
const feedbackREQ = path + '/feedback' // 訪客提交留言
const getCaptcha = base + '/captcha?token=' // 獲取驗證碼

// request(REQ) Function  ---------------------------

/**
 * 訪客結束會話
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const visitorFinishConFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + visitorFinishConREQ + params.id + '/finish?userId=' + params.userId, {
    method: 'PUT',
    body: null,
    onResponse: callback
  })
}

/**
 * (訪客)獲取對話歷史訊息
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const visitorHistoryFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(
    apiBaseUrl + visitorHistoryREQ + params.userId + '&tenantId=' + params.tenantId + '&channelId=' + params.channelId + '&pageNum=' + params.pageNum + '&pageSize=' + params.pageSize, {
      method: 'GET',
      body: null,
      onResponse: callback
    })
}

/**
 * 訪客提交留言
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
const feedbackREQFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + feedbackREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 獲取驗證碼
 * @param {*} token 隨機碼uuid
 * @param {*} time 時間戳
 * @param {Object} callback 回調函數
 * @returns
 */
const getCaptchaFn = (token, time, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getCaptcha + token + '&time=' + time, {
    method: 'GET',
    onResponse: callback
  })
}

export {
  visitorFinishConFn,
  visitorHistoryFn,
  feedbackREQFn,
  getCaptchaFn
}
