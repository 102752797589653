
<template>
  <div class="aside">
    【廣告】【評價】
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
@import "assets/styles/pages/chat.scss";
</style>
