
// scroll保持在最底部
export const scrollBottom = (scrollBlock) => { // scroll保持在最底部
  nextTick(() => {
    if (scrollBlock) {
      const scrollBottomValue = scrollBlock.scrollTop = scrollBlock.scrollHeight
      return scrollBottomValue
    }
  })
}
