<template>
  <div id="chat">
    <!-- 分配成功 -->

    <template v-if="assignedStatus == 'success'">
      <ChatInChat
        ref="childComponentRef"
        :is-finished-con="isFinishedCon"
        :visitor-end-chat-enabled="channel.visitorEndChatEnabled"
      />
      <!-- 邀請評價 -->
      <template v-if="channel.satisfactionRatingEnabled && !channel.visitorRatingEnabled">
        <LazyChatInviteRating
          :is-show="onInviteRating"
          :rating-info="ratingInfo"
          :rating-prompt="channel.satisfactionRatingPrompt"
        />
      </template>
    </template>
    <!-- 進留言 -->
    <template v-else-if="assignedStatus == 'in_feedback'">
      <LazyChatInFeedback :in-feedback-form-info="channel" :customer-id="customerId" />
    </template>
    <!-- 進排隊 -->
    <template v-else-if="assignedStatus == 'in_queue'">
      <LazyChatInQueue :queue-info="queueInfo" />
    </template>
    <template v-else-if="assignedStatus == 'failed'">
      <LazyChatInFeedbackFailed />
    </template>
  </div>
</template>

<script setup>
import socketIO from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'
import { visitorFinishConFn, visitorHistoryFn } from '../../api/message/customers'

const userInfo = useUserInfo()
const convInfo = useConvInfo()
const uuid = uuidv4()

onBeforeMount(() => {
  // getUsersList()
  newConFn()
  localStorage.setItem('sucRating', false)
})

let _socket
const establishConnection = (id) => { // 建立連線
  _socket = socketIO('wss://500ef3.com/im/user?id=' + id, {
    transports: ['websocket', 'polling']
  })
  return _socket
}
const disconnect = () => { // 斷開連線
  _socket.disconnect()
}

// 對話內容 ----
const massages = reactive(convInfo.currentChatMsg)

// 分配狀態(成功、進留言、進排隊)
const assignedStatus = ref('')

// 分配成功回傳值
const channel = ref()
const conversation = ref() // (對話Id、tenantId、channelId)
const customerId = ref('') // 訪客Id

// 排隊訊息
const queueInfo = ref({})

// newConversation ----
const url = useRoute()
const channelUrlID = url.params.channelUrl // 取得channelUrl
const agentUrlId = url.query.userId // 取得客服id
const departmentUrlId = url.query.deptId // 取得部門id
const newCon = {
  requestId: uuid, // 隨機碼
  signature: '123', // TODO 未完成，暫時先隨便帶
  reqConversation: {
    channelUrl: channelUrlID,
    agentId: agentUrlId,
    departmentId: departmentUrlId
  }
}

const childComponentRef = ref(null)

const queueContent = ref('')

const newConFn = (continueCon) => {
  const getUserId = localStorage.getItem('userId') || ''
  establishConnection(getUserId)

  _socket.on('user_event', (id) => {
    customerId.value = id.userId // 訪客id
    localStorage.setItem('userId', id.userId)
    _socket.emit('newConversation', newCon) // 發起會話
  })

  _socket.on('assigned', (assigned) => {
    if (assigned.status === 'success') {
      // 分配成功
      assignedStatus.value = 'success'

      channel.value = assigned.channel
      conversation.value = assigned.conversation
      customerId.value = assigned.customerId
      userInfo.agentUsersMap.set(assigned.agent.agentId, assigned.agent)
      getVisHist()
    } else if (assigned.status === 'in_feedback') {
      // 進留言
      assignedStatus.value = 'in_feedback'
      channel.value = assigned.channel
      customerId.value = assigned.customerId
    } else if (assigned.status === 'in_queue') {
      // TODO 進排隊(未完成)
      assignedStatus.value = 'in_queue'
      queueInfo.value = {
        queueCount: assigned.queueCount, // 排隊數量
        channelQueueCountEnabled: assigned.channel.queueCountEnabled,
        channelQueuePromptText: assigned.channel.queuePromptText, // 訪客排隊提示消息
        channelQueueTimeoutText: assigned.channel.queueTimeoutText // 排隊超時設置(文字)
      }
    } else if (assigned.status === 'failed') {
      assignedStatus.value = 'failed'
    } else {
      console.log('error')
    }
  })

  // 有新訊息
  _socket.on('message', function (newMsgObj) {
    const newMsg = newMsgObj && newMsgObj.message

    // 排隊超時(文字)
    if (newMsgObj.message.msgType === 'queue_timeout_prompt') {
      queueContent.value = newMsgObj.message.content
    } else {
      // TODO (優化對話)
      markUser(newMsg)
      convInfo.currentChatMsg.push(newMsg)

      // 卷軸滾到最底
      scrollBottom(childComponentRef.value.$refs.convContent)
    }
  })

  _socket.on('finished', function (status) {
    if (status.status === 'success') {
      disconnect()
      isShowBottomBlock()
    }

    // 排隊超時斷開連線
    if (status.endReason === 'queue_timeout') {
      convInfo.queueContent = queueContent.value
    }
  })

  // 邀請評價
  _socket.on('invite_rating', function (info) {
    inviteRatingFn(info)
  })

  // TODO 接收失敗(用requestId比對)
  // _socket.on('error', function (error) {
  //   console.log('error', error)
  // })

  if (continueCon) { isShowBottomBlock() } // 繼續對話
}

// TODO 監聽斷開事件
// _socket.on('disconnect', function () {
//   確認收到disconnect後的行為
// })

// 發送對話 ----
const sendMsgFn = (msg) => {
  const resUuid = uuidv4()
  const newInputContent = msg.trim()
  if (newInputContent.length === 0) {
    return
  }
  const newMessage = {
    requestId: resUuid, // 隨機碼
    signature: '123', // TODO 未完成，暫時先隨便帶
    message: {
      content: newInputContent,
      conversationId: conversation.value.id,
      channelId: conversation.value.channelId,
      tenantId: conversation.value.tenantId
    }
  }
  _socket.emit('message', newMessage)
}

// 結束對話 ----
const finishConFn = () => {
  const yes = confirm('確定離開對話嗎')
  const params = {
    id: conversation.value.id,
    userId: localStorage.getItem('userId') || ''
  }
  if (yes) {
    visitorFinishConFn(params)
    // 判斷是否要填【滿意度評價】
    const isShowRatingFrom = localStorage.getItem('sucRating')
    if (channel.value.satisfactionRatingEnabled && !channel.value.visitorRatingEnabled && isShowRatingFrom === 'false') {
      const info = {
        conversationId: conversation.value.id,
        customerId: conversation.value.customerId,
        tenantId: conversation.value.tenantId
      }
      inviteRatingFn(info)
    }
  }
}

// 獲取訪客對話歷史訊息 ----
const getVisHist = () => {
  const params = {
    userId: customerId.value,
    tenantId: conversation.value.tenantId,
    channelId: conversation.value.channelId,
    pageNum: 0,
    pageSize: 20
  }
  const onResponse = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      res.data.data.forEach((item) => {
        // TODO (優化對話)
        markUser(item)
        convInfo.currentChatMsg.push(item)
      })

      // 卷軸滾到最底
      scrollBottom(childComponentRef.value.$refs.convContent)
    }
  }
  visitorHistoryFn(params, onResponse)
}

// 標示客服、訪客 ----
const markUser = (msg) => {
  // 客服資料

  const agentInfo = userInfo.agentUsersMap.get(msg.agentId)

  msg.userName = agentInfo.username
  msg.agentAvatar = agentInfo.avatar

  // 區分客服、訪客
  if (msg.senderType === 'customer') {
    msg.group = 'right'
  } else if (msg.senderType === 'system' && msg.msgType === 'welcome') {
    msg.group = 'center'
  } else {
    msg.group = 'left'
  }

  // 是否顯示頭像
  if (convInfo.currentChatMsg.length > 0) {
    const lastSendMsgUser = convInfo.currentChatMsg.length - 1
    msg.show = convInfo.currentChatMsg[lastSendMsgUser].group !== msg.group
  }
}

// 底部輸入框 ----
const isFinishedCon = ref(false)
const isShowBottomBlock = () => {
  isFinishedCon.value = !isFinishedCon.value
}

// 邀請評價
const onInviteRating = ref(false)
const ratingInfo = ref()
const inviteRatingFn = (info) => {
  const currenConId = massages[massages.length - 1].conversationId
  if (info && currenConId === info.conversationId) {
    onInviteRating.value = true
    ratingInfo.value = {
      conId: info.conversationId,
      customerId: info.customerId,
      tenantId: info.tenantId
    }
  }
}
const closeRatingDlg = () => {
  onInviteRating.value = false
}

// 獲取用戶列表
const getUsersList = async () => {
  const params = {
    pageNum: 0,
    pageSize: 20
  }
  await userInfo.getAgentsUserList(params)
}

provide('chatProvide', {
  closeRatingDlg,
  sendMsgFn, // 發送對話
  finishConFn, // 結束對話
  newConFn // 繼續對話
})
</script>

<style lang="scss" scoped>
@import "assets/styles/pages/chat.scss";

#chat {
  height: 100vh;

  .statusSuccess { // 分配成功
    display: flex; justify-content: space-between;

    :deep(.v-btn) {
      min-width:20px;
    }
    :deep(.v-field__outline) {
      display: none;
    }
  }
}
</style>
